import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Icon from "../../dsm/icons/icon"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import { getInverseColour } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Pagination = ({ pageNumber, hasNextPage, totalPages }) => {
  if (pageNumber === 1 && !hasNextPage) return null

  const { lightSkin, shape, secondaryColor } = getFlexiOptionsBranding()

  return (
    <Wrapper
      shape={shape}
      lightSkin={lightSkin}
      secondaryColor={secondaryColor}
    >
      {pageNumber > 1 ? (
        <Link
          className="prev page-numbers"
          to={
            pageNumber > 2
              ? `/${process.env.GATSBY_BLOG_SLUG}/page/${pageNumber - 1}/`
              : `/${process.env.GATSBY_BLOG_SLUG}/`
          }
        >
          <Icon name="arrow-left" hue="tusk" shade="600" />
          Previous Page
        </Link>
      ) : (
        <div className="empty" />
      )}

      <span aria-current="page" className="page-numbers current">
        <span className="meta-nav screen-reader-text">Page </span>
        {pageNumber} of {totalPages}
      </span>

      {hasNextPage ? (
        <Link
          className="next page-numbers"
          to={`/${process.env.GATSBY_BLOG_SLUG}/page/${pageNumber + 1}/`}
        >
          Next Page
          <Icon name="arrow-right" hue="tusk" shade="600" />
        </Link>
      ) : (
        <div className="empty" />
      )}
    </Wrapper>
  )
}
export default Pagination

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  align-items: center;
  text-align: center;
  justify-items: center;
  a {
    border: none;
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[400]};
    background-color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.tusk[300] : theme.colors.midnight[200]};
    &:hover {
      border: none;
      cursor: pointer;
      background-color: ${({ secondaryColor }) => secondaryColor};
      color: ${({ secondaryColor }) => getInverseColour(secondaryColor)};
      #icon {
        fill: ${({ secondaryColor }) => getInverseColour(secondaryColor)};
      }
    }
    display: grid;
    padding: 14px 20px;
    border-radius: ${({ shape }) => shape};

    #icon {
      fill: ${({ theme, lightSkin }) =>
        lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[400]};
    }
  }
  .prev {
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    text-align: left;
    justify-self: flex-start;
  }
  .next {
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    text-align: right;
    justify-self: flex-end;
  }
  .page-numbers {
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[400]};
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr 1fr;
    .page-numbers.current {
      display: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    grid-template-columns: 1fr;
    .empty {
      display: none;
    }
    a {
      width: calc(100% - 40px);
      margin-bottom: 10px;
    }
  }
`
